import { FC, Fragment, useEffect, useState } from 'react';
import { CopyIcon, DashboardLayout, SuccessIconLarge, TooltipCustomCenter } from 'Components';
import { useCardType, useLocalization } from 'Hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { fontSizes, gutters } from 'Theme/Variables';
import { routePath } from 'Navigator/routes';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './CardApplicationUpdateSuccess.scss';
import { useDispatch } from 'react-redux';
import { clearApplicationData } from 'Stores/CardApplication';

type CardApplicationUpdateSuccessProps = RouteComponentProps<{}, StaticContext>;

const CardApplicationUpdateSuccess: FC<CardApplicationUpdateSuccessProps> = ({ location: { state } }: any) => {
  const {
    web_application_updated,
    web_application_no,
    web_card_cargo,
    web_application_received,
    web_application_track,
    web_application_sms,
    card_success_center,
    web_application_copy,
    web_university_success_text,
  } = useLocalization();
  const { cardPath } = useCardType(state.pc);
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearApplicationData({ withoutCard: false, withoutStep: true }));
  }, []);
  return (
    <DashboardLayout>
      <Fragment>
        {show && (
          <Box width={'100%'} display="flex" justifyContent="center">
            <TooltipCustomCenter show={show} title={web_application_copy} onClose={() => setShow(false)} />
          </Box>
        )}
        <div id="cardApplicationUpdateSuccess">
          <Typography variant="h3" align="center">
            <Box fontWeight={600}>{state.isApplication ? web_application_received : web_application_updated}</Box>
          </Typography>
          <Typography align="center">
            <Box fontSize={fontSizes.regular} mt={gutters.regular}>
              {state.campusId > 0
                ? web_university_success_text
                : state.fromCenter
                ? card_success_center
                : state.isApplication
                ? web_card_cargo
                : web_card_cargo}
            </Box>
          </Typography>
          <div className="cardApplicationUpdateSuccess__card-area">
            <SuccessIconLarge className="cardApplicationUpdateSuccess__card-area__success" />
            <img className="cardApplicationUpdateSuccess__card-area__img" src={cardPath} />
          </div>
          <Typography align="center">
            <Box display="flex" alignItems="center" fontSize={fontSizes.regular} mt={gutters.regular}>
              {`${web_application_no}: ${state.id}`}

              <CopyToClipboard onCopy={() => setShow(true)} text={`${state.id}`}>
                <CopyIcon className="cardApplicationUpdateSuccess__card-area__copy" />
              </CopyToClipboard>
            </Box>
          </Typography>
          <Box mt={gutters.big} />
          <Button
            onClick={() => history.push(routePath.cardApplications)}
            style={{ width: 280 }}
            variant="contained"
            color="secondary"
          >
            {web_application_track}
          </Button>
        </div>
      </Fragment>
    </DashboardLayout>
  );
};
export default CardApplicationUpdateSuccess;
