import * as yup from 'yup';
import { errorMessages } from 'Config';

export const WelcomeSchema = yup.lazy(({ countryCode }) =>
  yup.object({
    phone: yup
      .string()
      .required(errorMessages.require)
      .min(countryCode === '90' ? 12 : 0, errorMessages.minPhone),
    countryCode: yup.string().required(),
  })
);
export type WelcomeSchemaInputs = yup.InferType<typeof WelcomeSchema>;

export const welcomeSchemaInitialValues: WelcomeSchemaInputs = {
  phone: '',
  countryCode: '90',
};
