import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  onGetCardListBodyData,
  onSaveCustomerCardBodyData,
  getRegisteredCardTransactionBodyData,
  saveRegisterCardTransactionBodyData,
  onGetCardTransactionListBodyData,
  InstrunctionListReqBodyData,
  onDeleteCustomerCardByClosedBodyData,
  onRegisterCardTransactionBodyData,
  deleteRegisteredDefaultCardTransactionBodyData,
  onCountInstructionListReqBodyData,
  changeCardNickNameBodyData,
  setDefaultCardBodyData,
  getSeasonTicketPriceReqBodyData,
  onCheckProductReqBodyData,
  onOnlineApplicationQueryBodyData,
  onOnlineApplicationCardQueryBodyData,
  onChangeRegisterCardNickNameBodyData,
  onAddApplicationBodyData,
  getCardVisaReqBodyData,
  onCustomerApplicationListBodyData,
  OnGetApplicationCenterListBodyData,
  OnUpdateApplicationBodyData,
} from 'Config';
import {
  OnSaveCustomerCardModel,
  OnGetCardTransactionListModel,
  InstrunctionListReqModel,
  OnCountInstructionListModel,
  GetSeasonTicketPriceModel,
  OnOnlineApplicationCardQueryItemModel,
  ProductPriceModel,
  GetCardVisaModel,
  CustomerCardApplicationListItemModel,
  ApplicationCenterListItemModel,
  OnGetAccountWithCardsData,
} from 'Models';
import { ApplicationDetailModel, ApplicationItemModel } from 'Models/ApplicationDetailModel';
import { RootState } from 'Stores/index';
import { cardItemDetailType } from 'Views/CardDetails/constant';
import { CardTransactionsState } from './types';

export const initialState: CardTransactionsState = {
  loading: false,
  otherCardStateLoading: false,
  onGetCardList: undefined,
  error: undefined,
  getCardListError: undefined,
  addCardStep: 0,
  activeCardTransactions: null,
  onGetOtherCardList: undefined,
  onSaveCustomerCardModel: undefined,
  mbSaveRegisteredCardModel: undefined,
  cards: [],
  otherCards: [],
  activeCardWaitingInstructions: undefined,
  cardInstructionList: undefined,
  cardTaskList: undefined,
  cardDeleteStep: -1,
  showUserBelongSomeoneModal: false,
  isShowCardFetchFailModalOpen: false,
  tempCardInfo: undefined,
  isUserAddingNewCardInTransactions: false,
  uploadTLStep: 0,
  activeSelectedCard: undefined,
  uploadSubscriptionStep: 0,
  paginationCount: 1,
  activeInProcessCard: undefined,
  isUserInUploadTLProcess: false,
  isUserInUploadSubscriptionProcess: false,
  isUserInAutomatiInstructionProcess: false,
  automaticInstructionStep: 0,
  cardSeasonTicketPrice: undefined,
  cardLowerLimit: undefined,
  istanbulCardTypeList: undefined,
  isOpenDeletePrivilegedDialog: false,
  isOpenCantApplyDialog: false,
  isOpenCantApplyForeign: false,
  isOpenAlreadyHave: false,
  cardVisaExpire: undefined,
  isReportNumbers: false,
  customerCardApplicationList: undefined,

  isUserHasVisaExpireWarning: false,
  activeCardApplicationDetail: undefined,
  loadBalanceWithoutMasterpass: false,

  onOnlineApplicationCardStatusCode: '',
  isOpenOnlineAppcationCardDialog: false,
  CustodyTckn: undefined,
  BirthDate: undefined,
  HashData: undefined,
  HasCustodyRelation: undefined,
  HideOrganizationStep: false,
  CustodyCustomerNumber: undefined,
  SubProductCode: undefined,
  DigitalAccount: {
    iban: '',
    balanceTotal: 0,
    readyLinitAvailable: 0,
    balances: [],
    cardMasters: [],
  },
  isDigitalActive: false,
};

const MAX_STEP_COUNT = 5;

const cardTransactions = createSlice({
  name: 'cardTransactions',
  initialState,
  reducers: {
    setRelationData: (state, action: any) => {
      if (action.payload?.CustodyTckn) {
        state.CustodyTckn = action.payload?.CustodyTckn;
      }
      if (action.payload?.BirthDate) {
        state.BirthDate = action.payload?.BirthDate;
      }
      if (action.payload?.HashData) {
        state.HashData = action.payload?.HashData;
      }
      if (typeof action.payload?.HideOrganizationStep === 'boolean') {
        state.HideOrganizationStep = action.payload?.HideOrganizationStep;
      }
      if (action.payload?.CustodyCustomerNumber) {
        state.CustodyCustomerNumber = action.payload?.CustodyCustomerNumber;
      }
      state.SubProductCode = action.payload?.SubProductCode;
    },
    resetRelationData: (state) => {
      state.CustodyTckn = undefined;
      state.BirthDate = undefined;
      state.HashData = undefined;
      state.HideOrganizationStep = false;
      state.CustodyCustomerNumber = undefined;
      state.SubProductCode = undefined;
    },
    onGetCardListRequest: (state: CardTransactionsState, { payload }: PayloadAction<onGetCardListBodyData>) => {
      state.loading = true;
    },
    onGetCardListRequestSuccess: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = false;
      state.cards = payload;
    },
    onGetCardListRequestFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.getCardListError = payload;
      state.cards = [];
    },
    setCardListEmpty: (state: CardTransactionsState) => {
      state.cards = [];
    },
    mbGetOtherCardListRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<getRegisteredCardTransactionBodyData>
    ) => {
      state.otherCardStateLoading = true;
    },
    mbGetOtherCardListRequestSuccess: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.otherCardStateLoading = false;
      state.otherCards = payload;
    },
    mbGetOtherCardListRequestFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.otherCardStateLoading = false;
      state.getCardListError = payload;
      state.otherCards = [];
      state.isShowCardFetchFailModalOpen = true;
    },
    showCardFetchFailModalClose: (state: CardTransactionsState) => {
      state.isShowCardFetchFailModalOpen = false;
    },
    setOtherCardListEmpty: (state: CardTransactionsState) => {
      state.otherCards = [];
    },
    onSaveCustomerCardRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onSaveCustomerCardBodyData>
    ) => {
      state.loading = true;
    },
    onSaveCustomerCardSuccess: (state: CardTransactionsState, { payload }: PayloadAction<OnSaveCustomerCardModel>) => {
      state.loading = false;
      state.onSaveCustomerCardModel = payload;
    },
    onSaveCustomerCardFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    showUserBelongToSomeoneElseModal: (state: CardTransactionsState) => {
      state.showUserBelongSomeoneModal = true;
      state.loading = false;
    },
    closeUserBelongToSomeoneElseModal: (state: CardTransactionsState) => {
      state.showUserBelongSomeoneModal = false;
      state.loading = false;
    },
    setClearError: (state: CardTransactionsState) => {
      state.error = undefined;
    },
    mbSaveRegisterCardRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<saveRegisterCardTransactionBodyData>
    ) => {
      state.loading = true;
    },
    mbSaveRegisterCardSuccess: (state: CardTransactionsState, { payload }: PayloadAction<OnSaveCustomerCardModel>) => {
      state.loading = false;
      state.mbSaveRegisteredCardModel = payload;
    },
    mbSaveRegisterCardFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    setTempCardInfo: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.tempCardInfo = payload;
    },
    incrementAddCardStep: (state: CardTransactionsState) => {
      if (state.addCardStep < MAX_STEP_COUNT) state.addCardStep = ++state.addCardStep;
    },
    decrementAddCardStep: (state: CardTransactionsState) => {
      if (state.addCardStep > 0) state.addCardStep = --state.addCardStep;
    },
    resetAddCardStep: (state: CardTransactionsState) => {
      state.addCardStep = 0;
    },
    setAddCardStep: (state: CardTransactionsState, { payload }: PayloadAction<number>) => {
      state.addCardStep = payload;
    },
    onGetCardTransactionListRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onGetCardTransactionListBodyData>
    ) => {
      state.loading = true;
    },
    onGetCardTransactionListSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<OnGetCardTransactionListModel>
    ) => {
      state.loading = false;
      state.activeCardTransactions = payload.data.TransactionList;
      state.paginationCount = payload.data.TotalPageCount;
    },
    onGetCardTransactionListFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
      state.activeCardTransactions = null;
    },

    onRegisterCardTransactionRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onRegisterCardTransactionBodyData>
    ) => {
      state.loading = true;
    },
    onRegisterCardTransactionSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<OnGetCardTransactionListModel>
    ) => {
      state.loading = false;
      state.activeCardTransactions = payload.data.TransactionList;
      state.paginationCount = payload.data.TotalPageCount;
    },
    onRegisterCardTransactionFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
      state.activeCardTransactions = null;
    },
    setActiveCardTransactionListEmpty: (state: CardTransactionsState) => {
      state.activeCardTransactions = null;
    },
    getInstructionListRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<InstrunctionListReqBodyData>
    ) => {
      state.loading = true;
    },
    getInstructionListSuccess: (state: CardTransactionsState, { payload }: PayloadAction<InstrunctionListReqModel>) => {
      state.loading = false;
      state.activeCardWaitingInstructions = payload.data.InstructionList;
      state.cardTaskList = payload.data.TaskList;
    },
    getInstructionListFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
      state.activeCardWaitingInstructions = undefined;
    },
    setActiveInstructionListEmpty: (state: CardTransactionsState) => {
      state.activeCardWaitingInstructions = undefined;
      state.cardTaskList = undefined;
    },
    //DELETE CARD
    onDeleteCustomerCardByClosedRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onDeleteCustomerCardByClosedBodyData>
    ) => {
      state.loading = true;
    },
    onDeleteCustomerCardByClosedSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onDeleteCustomerCardByClosedFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    //other card delete transaction
    onDeleteRegisteredCardTransactionRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<deleteRegisteredDefaultCardTransactionBodyData>
    ) => {
      state.loading = true;
    },
    onDeleteRegisteredCardTransactionSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onDeleteRegisteredCardTransactionFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },

    setDeleteCardStep: (state: CardTransactionsState, { payload }: PayloadAction<number>) => {
      state.cardDeleteStep = payload;
    },
    resetDeleteCardStep: (state: CardTransactionsState) => {
      state.cardDeleteStep = 0;
    },
    setIsUserAddingNewCardInTransactions: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isUserAddingNewCardInTransactions = payload;
    },
    setUploadTLStep: (state: CardTransactionsState, { payload }: PayloadAction<number>) => {
      state.uploadTLStep = payload;
    },
    setActiveSelectedCard: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.activeSelectedCard = payload;
      state.isDigitalActive = false;
    },
    setDigitalCardActive: (state: CardTransactionsState) => {
      state.isDigitalActive = true;
      state.activeSelectedCard = state.DigitalAccount;
    },
    setNormalizeCardTransactionStates: (state: CardTransactionsState) => state,

    showUserAddedCardDetail: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    setCardStateLoading: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setInActiveCard: (state: CardTransactionsState, { payload }: PayloadAction<cardItemDetailType | undefined>) => {
      state.activeInProcessCard = payload;
    },
    // InstructionListFor Usercards
    onCountInstructionListRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onCountInstructionListReqBodyData>
    ) => {
      state.loading = true;
    },
    onCountInstructionListSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<OnCountInstructionListModel>
    ) => {
      state.loading = false;
      state.cardInstructionList = payload.data.TaskorInstructionList;
    },
    onCountInstructionListFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    // Card Settings Transactions
    changeCardNickNameRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<changeCardNickNameBodyData>
    ) => {
      state.loading = false;
    },
    changeCardNickNameRequestSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    changeCardNickNameRequestError: (state: CardTransactionsState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    //Set Favorite Card
    setDefaultCardRequest: (state: CardTransactionsState, { payload }: PayloadAction<setDefaultCardBodyData>) => {
      state.loading = true;
    },
    setDefaultCardRequestSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    setDefaultCardRequestError: (state: CardTransactionsState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setIsUserInUploadTLProcess: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isUserInUploadTLProcess = payload;
    },
    setIsUserInUploadSubscriptionProcess: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isUserInUploadSubscriptionProcess = payload;
    },
    setUploadSubscriptionStep: (state: CardTransactionsState, { payload }: PayloadAction<number>) => {
      state.uploadSubscriptionStep = payload;
    },
    setIsUserInAutomaticInstructionProcess: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isUserInAutomatiInstructionProcess = payload;
    },
    setAutomaticInstructionStep: (state: CardTransactionsState, { payload }: PayloadAction<number>) => {
      state.automaticInstructionStep = payload;
    },
    // Get card season ticket loadable
    getSeasonTicketLoadableRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<getSeasonTicketPriceReqBodyData>
    ) => {
      state.loading = true;
    },
    getSeasonTicketLoadableRequestSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<GetSeasonTicketPriceModel>
    ) => {
      state.loading = false;
      state.cardSeasonTicketPrice = payload.data;
    },
    getSeasonTicketLoadableRequestError: (state: CardTransactionsState, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setSeasonTicketLoadable: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.cardSeasonTicketPrice = payload;
    },
    onCheckProductRequest: (state: CardTransactionsState, { payload }: PayloadAction<onCheckProductReqBodyData>) => {
      state.loading = true;
    },
    onCheckProductSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onCheckProductFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    setLowerLimit: (state: CardTransactionsState, { payload }: PayloadAction<string | undefined>) => {
      state.cardLowerLimit = payload;
    },
    onCheckCardIsUploadableSubsRequest: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    onCheckCardIsUploadableSubsSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onCheckCardIsUploadableSubsFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    //new card application transactions
    onOnlineApplicationCardRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onOnlineApplicationCardQueryBodyData>
    ) => {
      state.loading = true;
    },
    onOnlineApplicationCardSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<Array<OnOnlineApplicationCardQueryItemModel>>
    ) => {
      state.istanbulCardTypeList = payload;
      state.loading = false;
    },
    onOnlineApplicationCardFail: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onOnlineApplicationRequest: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
    },
    onOnlineApplicationSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    openOnOnlineApplicationDialog: (state: CardTransactionsState) => {
      state.isOpenOnlineAppcationCardDialog = true;
    },
    closeOnOnlineApplicationDialog: (state: CardTransactionsState) => {
      state.isOpenOnlineAppcationCardDialog = false;
    },
    onOnlineApplicationFail: (state: CardTransactionsState, { payload }: PayloadAction<string>) => {
      state.onOnlineApplicationCardStatusCode = payload;
      state.loading = false;
    },
    openDeletePrivilegedDialog: (state: CardTransactionsState) => {
      state.isOpenDeletePrivilegedDialog = true;
    },
    closeDeletePrivilegedDialog: (state: CardTransactionsState) => {
      state.isOpenDeletePrivilegedDialog = false;
    },
    openCantApplyDialog: (state: CardTransactionsState) => {
      state.isOpenCantApplyDialog = true;
    },
    closeCantApplyDialog: (state: CardTransactionsState) => {
      state.isOpenCantApplyDialog = false;
    },
    openCantApplyForeign: (state: CardTransactionsState) => {
      state.isOpenCantApplyForeign = true;
    },
    closeCantApplyForeign: (state: CardTransactionsState) => {
      state.isOpenCantApplyForeign = false;
    },
    openAlreadyHave: (state: CardTransactionsState) => {
      state.isOpenAlreadyHave = true;
    },
    closeAlreadyHave: (state: CardTransactionsState) => {
      state.isOpenAlreadyHave = false;
    },
    onChangeRegisterCardNickNameRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onChangeRegisterCardNickNameBodyData>
    ) => {
      state.loading = true;
    },
    onChangeRegisterCardNickNameSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    onChangeRegisterCardNickNameFail: (state: CardTransactionsState) => {
      state.loading = false;
    },

    getCardVisaRequest: (state: CardTransactionsState, { payload }: PayloadAction<getCardVisaReqBodyData>) => {
      state.loading = true;
    },
    getCardVisaSuccess: (state: CardTransactionsState, { payload }: PayloadAction<GetCardVisaModel>) => {
      state.loading = false;
      state.cardVisaExpire = {
        VisaExpireDate: payload.data.VisaExpireDate,
        VisaPrice: payload.data.VisaPrice,
      };
    },
    getCardVisaFail: (state: CardTransactionsState) => {
      state.loading = false;
    },
    setCardVisaDate: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = false;
    },
    checkApplicationTypeFourRequest: (state: CardTransactionsState, { payload }: PayloadAction<any>) => {
      state.loading = true;
      state.isReportNumbers = false;
    },
    checkApplicationTypeFourSuccess: (state: CardTransactionsState) => {
      state.loading = false;
      state.isReportNumbers = true;
    },
    checkApplicationTypeFourFail: (state: CardTransactionsState) => {
      state.loading = false;
      state.isReportNumbers = false;
    },
    onCustomerApplicationListRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onCustomerApplicationListBodyData>
    ) => {
      state.loading = true;
    },
    onCustomerApplicationListSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<Array<CustomerCardApplicationListItemModel>>
    ) => {
      state.loading = false;
      state.customerCardApplicationList = payload;
    },
    onCustomerApplicationListFail: (state: CardTransactionsState) => {
      state.loading = false;
    },

    checkUserCardsHasVisaRequest: (state: CardTransactionsState, { payload }: PayloadAction<onGetCardListBodyData>) => {
      state.loading = true;
    },
    checkUserCardsHasVisaSuccess: (state: CardTransactionsState) => {
      state.loading = false;
    },
    checkUserCardsHasVisaFail: (state: CardTransactionsState) => {
      state.loading = false;
    },
    setIsUserHasVisaExpireWarning: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.isUserHasVisaExpireWarning = payload;
    },
    onGetActiveApplicationDetailRequest: (
      state: CardTransactionsState,
      { payload }: PayloadAction<onCustomerApplicationListBodyData>
    ) => {
      state.loading = true;
    },
    onGetActiveApplicationDetailSuccess: (
      state: CardTransactionsState,
      { payload }: PayloadAction<ApplicationItemModel>
    ) => {
      state.loading = false;
      state.activeCardApplicationDetail = payload;
    },
    onGetActiveApplicationDetailFail: (state: CardTransactionsState) => {
      state.loading = false;
    },
    setLoadBalanceWithoutMasterpass: (state: CardTransactionsState, { payload }: PayloadAction<boolean>) => {
      state.loadBalanceWithoutMasterpass = payload;
    },

    resetCardTransactionStore: (state: CardTransactionsState) => initialState,
    onGetAccountWithCardsRequest: (state: CardTransactionsState, action: PayloadAction<any>) => {
      state.loading = true;
    },
    onGetAccountWithCardsSuccess: (state: CardTransactionsState, action: PayloadAction<OnGetAccountWithCardsData>) => {
      state.loading = false;
      state.DigitalAccount = {
        iban: action.payload.Iban ?? '',
        balanceTotal: action.payload.BalanceTotal ?? 0,
        readyLinitAvailable: action.payload.ReadyLimitAvailable ?? 0,
        balances: action.payload.Balances ?? [],
        cardMasters: action.payload.CardMasters ?? [],
      };
    },
    onGetAccountWithCardsFail: (state: CardTransactionsState) => {
      state.loading = false;
    },
  },
});

export const storeCardTransactions = (state: RootState) => state.cardTransactions;

export const cardIsLoading = (state: RootState) => state.cardTransactions.loading;

export const otherCardIsLoading = (state: RootState) => state.cardTransactions.otherCardStateLoading;

export const getDigitalAccountInformation = (state: RootState) => state.cardTransactions.DigitalAccount;

export const {
  onGetCardListRequest,
  onGetCardListRequestSuccess,
  onGetCardListRequestFail,
  onSaveCustomerCardRequest,
  onSaveCustomerCardSuccess,
  onSaveCustomerCardFail,
  showUserBelongToSomeoneElseModal,
  closeUserBelongToSomeoneElseModal,
  incrementAddCardStep,
  decrementAddCardStep,
  resetAddCardStep,
  setAddCardStep,
  mbGetOtherCardListRequest,
  mbGetOtherCardListRequestSuccess,
  mbGetOtherCardListRequestFail,
  mbSaveRegisterCardRequest,
  mbSaveRegisterCardSuccess,
  mbSaveRegisterCardFail,
  onGetCardTransactionListRequest,
  onGetCardTransactionListSuccess,
  onGetCardTransactionListFail,
  getInstructionListRequest,
  getInstructionListSuccess,
  getInstructionListFail,
  onDeleteCustomerCardByClosedRequest,
  onDeleteCustomerCardByClosedSuccess,
  onDeleteCustomerCardByClosedFail,
  resetDeleteCardStep,
  setDeleteCardStep,
  setCardListEmpty,
  setOtherCardListEmpty,
  setActiveCardTransactionListEmpty,
  setActiveInstructionListEmpty,
  onRegisterCardTransactionRequest,
  onRegisterCardTransactionSuccess,
  onRegisterCardTransactionFail,
  onDeleteRegisteredCardTransactionRequest,
  onDeleteRegisteredCardTransactionSuccess,
  onDeleteRegisteredCardTransactionFail,
  setClearError,
  showCardFetchFailModalClose,
  setTempCardInfo,
  setUploadTLStep,
  setActiveSelectedCard,
  setDigitalCardActive,
  setNormalizeCardTransactionStates,
  showUserAddedCardDetail,
  setCardStateLoading,
  setInActiveCard,
  onCountInstructionListRequest,
  onCountInstructionListSuccess,
  onCountInstructionListFail,
  changeCardNickNameRequestError,
  changeCardNickNameRequestSuccess,
  changeCardNickNameRequest,
  setDefaultCardRequestError,
  setDefaultCardRequestSuccess,
  setDefaultCardRequest,
  setIsUserInUploadTLProcess,
  setIsUserInUploadSubscriptionProcess,
  setIsUserAddingNewCardInTransactions,
  setIsUserInAutomaticInstructionProcess,
  setAutomaticInstructionStep,
  getSeasonTicketLoadableRequest,
  getSeasonTicketLoadableRequestSuccess,
  getSeasonTicketLoadableRequestError,
  setSeasonTicketLoadable,
  onCheckProductRequest,
  onCheckProductSuccess,
  onCheckProductFail,
  setUploadSubscriptionStep,
  setLowerLimit,
  onCheckCardIsUploadableSubsRequest,
  onCheckCardIsUploadableSubsSuccess,
  onCheckCardIsUploadableSubsFail,
  onOnlineApplicationRequest,
  onOnlineApplicationSuccess,
  onOnlineApplicationFail,
  onOnlineApplicationCardRequest,
  onOnlineApplicationCardSuccess,
  onOnlineApplicationCardFail,
  openDeletePrivilegedDialog,
  closeDeletePrivilegedDialog,
  openCantApplyDialog,
  closeCantApplyDialog,
  openCantApplyForeign,
  closeCantApplyForeign,
  openAlreadyHave,
  closeAlreadyHave,
  onChangeRegisterCardNickNameRequest,
  onChangeRegisterCardNickNameSuccess,
  onChangeRegisterCardNickNameFail,
  getCardVisaRequest,
  getCardVisaSuccess,
  getCardVisaFail,
  setCardVisaDate,
  checkApplicationTypeFourRequest,
  checkApplicationTypeFourSuccess,
  checkApplicationTypeFourFail,
  onCustomerApplicationListRequest,
  onCustomerApplicationListSuccess,
  onCustomerApplicationListFail,

  checkUserCardsHasVisaRequest,
  checkUserCardsHasVisaSuccess,
  checkUserCardsHasVisaFail,
  setIsUserHasVisaExpireWarning,
  onGetActiveApplicationDetailRequest,
  onGetActiveApplicationDetailSuccess,
  onGetActiveApplicationDetailFail,
  resetCardTransactionStore,
  setLoadBalanceWithoutMasterpass,
  openOnOnlineApplicationDialog,
  closeOnOnlineApplicationDialog,
  setRelationData,
  resetRelationData,
  onGetAccountWithCardsRequest,
  onGetAccountWithCardsSuccess,
  onGetAccountWithCardsFail,
} = cardTransactions.actions;

export default cardTransactions.reducer;
