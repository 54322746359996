import { Box, BoxProps, Divider } from '@material-ui/core';
import React from 'react';
import DigitalAccount from 'Assets/Images/digital_account_right.png';
import { fontSizes, colors } from 'Theme/Variables';
import { useDigitalAccount, useLocalization } from 'Hooks';
import { cardBalanceNormalize } from 'Utils';

import './DigitalAccountCard.scss';

type DigitalAccountCard = BoxProps & {
  showDivider?: boolean;
  cursor?: 'pointer' | 'default';
  rightSideIcon?: React.ReactNode;
  containerStyle?: React.CSSProperties;
};

const DigitalAccountCard: React.FC<DigitalAccountCard> = ({
  cursor = 'pointer',
  showDivider = false,
  rightSideIcon,
  containerStyle,
  ...boxProps
}) => {
  const { web_digital_account_title, web_my_account_total_balance_text } = useLocalization();

  const digitalAccount = useDigitalAccount();

  return (
    <Box id="digitalCard" style={{ cursor }} {...boxProps}>
      <div className={'digitalCard-container'} style={containerStyle}>
        <Box className={`digitalCard-container--image`} display="flex">
          <img src={DigitalAccount} alt="" />
        </Box>
        <Box
          overflow="hidden"
          marginLeft={fontSizes.big}
          flex={1}
          className={showDivider ? 'digitalCard-container--text-container' : ''}
        >
          <Box className={'digitalCard-container' + (showDivider ? '--divider-title' : '--title')}>
            {web_digital_account_title}
          </Box>
          {/* {!showDivider && (
            <Box className="digitalCard-container--title">
              0432 26CA 4A48 80
              {web_my_account_total_balance_text}
            </Box>
          )} */}
          {showDivider && (
            <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: '#f5f5f6' }} />
          )}
          <Box>
            {showDivider && <Box className="digitalCard-container--title">{web_my_account_total_balance_text}</Box>}
            <Box fontSize={'20px'} color={colors.separator} fontWeight="700">
              {cardBalanceNormalize(digitalAccount.balanceTotal)} <span style={{ fontSize: '14px' }}>TL</span>
            </Box>
          </Box>
        </Box>
        {rightSideIcon}
      </div>
    </Box>
  );
};

export default DigitalAccountCard;
