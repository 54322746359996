import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formNameKeys } from 'Config';
import {
  onLoginBodyData,
  onConfirmLoginSmsBodyData,
  onSendTokenChangePasswordBodyData,
  onConfirmSmsBodyData,
  insertOrUpdateHesCodeBodyData,
  onUpdateCommercialStatusReqBodyData,
  onChangeCustomerInfoReqBodyData,
  onChangePhoneValidationReqBodyData,
  organizationTypeListBodyData,
  organizationListBodyData,
  GetPinsBodyData,
} from 'Config/ServiceConfig';
import {
  OnLoginModel,
  OnConfirmLoginSmsModel,
  OnSendTokenChangePasswordModel,
  OnConfirmSmsModel,
  OnGetCustomerInfoModel,
  OnChangeCustomerInfoModel,
  OrganizationTypeListItemModel,
  OrganizationListModel,
  OrganizationListItemModel,
  GetTagsModel,
  GetPinsModel,
  PinsItemModel,
} from 'Models';
import store, { RootState } from 'Stores';
import { userInfoTypes } from 'Views/UserInfo/constant';
import { UserState } from './types';

const MAX_STEP_COUNT = 3;

export const initialState: UserState = {
  isLoading: false,
  userPassword: '',
  loginFormStep: 0,
  forgotPasswordFormStep: 0,
  hesCodeState: 0,
  userTcNo: '',
  birthDate: '',
  error: undefined,
  customerNumber: undefined,
  name: undefined,
  surname: undefined,
  job: undefined,
  currentAgreementList: null,
  sessionToken: undefined,
  checkHESCode: undefined,
  hesCode: '',
  cards: undefined,
  otherCards: undefined,
  userFavoriteCard: undefined,
  resetPassword: undefined,
  adressList: undefined,
  email: undefined,
  customerStatusId: undefined,
  commercialStatusChannelType: undefined,
  iban: undefined,
  citizenshipNumber: undefined,
  countryCode: undefined,
  phoneNumber: undefined,
  organizationsTypes: undefined,
  organizationsList: undefined,
  digitalAccount: undefined,
  h: undefined,
  isMiniAppLogin: false,
  reCaptchaToken: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onLoginRequest: (
      state: UserState,
      { payload }: PayloadAction<{ onLoginData: onLoginBodyData; formName: formNameKeys }>
    ) => {
      state.userPassword = payload.onLoginData.Password;
      state.isLoading = true;
    },
    onLoginRequestSuccess: (state: UserState, action: PayloadAction<OnLoginModel>) => {
      state.isLoading = false;
    },
    onLoginRequestFail: (state: UserState, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    onConfirmLoginSmsRequest: (state: UserState, { payload }: PayloadAction<onConfirmLoginSmsBodyData>) => {
      state.isLoading = true;
    },
    onConfirmLoginSmsRequestFail: (state: UserState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
    },
    onConfirmLoginSmsRequestSuccess: (state: UserState, action: PayloadAction<OnConfirmLoginSmsModel>) => {
      state.isLoading = false;
      state.name = action.payload.data.Name;
      state.surname = action.payload.data.Surname;
      state.customerNumber = action.payload.data.CustomerNumber;
      state.hesCode = action.payload.data.HESCode;
      state.checkHESCode = action.payload.data.CheckHESCode;
      state.sessionToken = action.payload.data.SessionToken;
      state.job = action.payload.data.Job;
      state.currentAgreementList = action.payload.data.CurrentAgreementList;
      state.birthDate = action.payload.data.BirthDate;
      state.resetPassword = action.payload.data.ResetPassword;
      state.userPassword = '';
      state.digitalAccount = action.payload.data.DigitalAccount;
    },
    onSendTokenChangePasswordRequest: (
      state: UserState,
      { payload }: PayloadAction<onSendTokenChangePasswordBodyData>
    ) => {
      state.birthDate = payload.BirthDate;
      state.userTcNo = payload.CitizenshipNumber;
      state.isLoading = true;
    },
    onSendTokenChangePasswordSuccess: (state: UserState, action: PayloadAction<OnSendTokenChangePasswordModel>) => {
      state.isLoading = false;
    },
    onSendTokenChangePasswordFail: (state: UserState, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    onConfirmSmsRequest: (state: UserState, { payload }: PayloadAction<onConfirmSmsBodyData>) => {
      state.isLoading = true;
    },
    onConfirmSmsRequestSuccess: (state: UserState, action: PayloadAction<OnConfirmSmsModel>) => {
      state.isLoading = false;
      state.h = action.payload.data.h;
    },
    onConfirmSmsRequestFail: (state: UserState, action: PayloadAction<string>) => {
      state.isLoading = false;
    },
    onConfirmSmsRequestForForgotPassword: (state: UserState, { payload }: PayloadAction<onConfirmSmsBodyData>) => {
      state.isLoading = true;
    },
    onConfirmSmsRequestForForgotPasswordFail: (state: UserState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
    },
    incrementLoginFormStep: (state: UserState) => {
      state.loginFormStep = 1;
    },
    decrementLoginFormStep: (state: UserState) => {
      state.loginFormStep = 0;
    },
    incrementForgotPasswordFormStep: (state: UserState) => {
      if (state.forgotPasswordFormStep < MAX_STEP_COUNT - 1)
        state.forgotPasswordFormStep = ++state.forgotPasswordFormStep;
    },
    decrementForgotPasswordFormStep: (state: UserState) => {
      if (state.forgotPasswordFormStep > 0) state.forgotPasswordFormStep = --state.forgotPasswordFormStep;
    },
    resetForgotPasswordFormStep: (state: UserState) => {
      state.forgotPasswordFormStep = 0;
    },

    insertOrUpdateHesCodeRequest: (state: UserState, { payload }: PayloadAction<insertOrUpdateHesCodeBodyData>) => {
      state.isLoading = true;
    },
    insertOrUpdateHesCodeRequestSuccess: (state: UserState, { payload }: PayloadAction<string>) => {
      state.isLoading = false;
      state.hesCode = payload;
    },
    insertOrUpdateHesCodeRequestFail: (state: UserState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    incrementHesCodeState: (state: UserState) => {
      state.hesCodeState = 1;
    },
    decrementHesCodeState: (state: UserState) => {
      state.hesCodeState = 0;
    },

    setUserFavoriteCard: (state: UserState, { payload }: PayloadAction<any>) => {
      state.userFavoriteCard = payload;
    },
    setFalseUserResetPassword: (state: UserState) => {
      state.resetPassword = false;
    },
    setUserAdditionalInfo: (state: UserState, { payload }: PayloadAction<OnGetCustomerInfoModel>) => {
      state.name = payload.data.Name;
      state.surname = payload.data.Surname;
      state.adressList = payload.data.AddressList; //
      state.email = payload.data.Email;
      state.customerStatusId = payload.data.CustomerStatusId;
      state.commercialStatusChannelType = payload.data.CommercialStatusChannelType;
      state.iban = payload.data.IBAN;
      state.citizenshipNumber = payload.data.CitizenshipNumber;
      state.countryCode = payload.data.CountryCode;
      state.phoneNumber = payload.data.Cellphone;
      state.job = payload.data.JobId.toString();
    },
    onUpdateCommercialStatusRequest: (
      state: UserState,
      { payload }: PayloadAction<onUpdateCommercialStatusReqBodyData>
    ) => {
      state.isLoading = true;
    },
    onUpdateCommercialStatusRequestSuccess: (state: UserState, { payload }: PayloadAction<number>) => {
      state.isLoading = false;
      state.commercialStatusChannelType = payload;
    },
    onUpdateCommercialStatusRequestFail: (state: UserState, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
    },
    onChangeCustomerInfoRequest: (state: UserState, { payload }: PayloadAction<onChangeCustomerInfoReqBodyData>) => {
      state.isLoading = true;
    },
    onChangeCustomerInfoRequestSuccess: (
      state: UserState,
      { payload }: PayloadAction<onChangeCustomerInfoReqBodyData>
    ) => {
      state.isLoading = false;
      switch (payload.TypeOfField) {
        case userInfoTypes.iban:
          state.iban = payload.Iban;
          break;
        case userInfoTypes.job:
          state.job = payload.Job;
          break;
        default:
          break;
      }
    },
    onChangeCustomerInfoRequestFail: (state: UserState, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
    },
    onChangePhoneValidationRequest: (
      state: UserState,
      { payload }: PayloadAction<onChangePhoneValidationReqBodyData>
    ) => {
      state.isLoading = true;
    },
    onChangePhoneValidationRequestSuccess: (
      state: UserState,
      { payload }: PayloadAction<onChangePhoneValidationReqBodyData>
    ) => {
      state.isLoading = false;
      state.countryCode = payload.CountryCode;
      state.phoneNumber = payload.CellPhone;
    },
    onChangePhoneValidationRequestFail: (state: UserState, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isLoading = false;
    },
    organizationTypeListRequest: (state: UserState, { payload }: PayloadAction<organizationTypeListBodyData>) => {
      state.isLoading = true;
    },
    organizationTypeListSuccess: (state: UserState, { payload }: PayloadAction<OrganizationTypeListItemModel[]>) => {
      state.organizationsTypes = payload;
    },
    organizationTypeListFail: (state: UserState) => {
      state.isLoading = false;
    },

    organizationListRequest: (state: UserState, { payload }: PayloadAction<organizationListBodyData>) => {
      state.isLoading = true;
    },
    organizationListSuccess: (
      state: UserState,
      { payload }: PayloadAction<OrganizationListItemModel[] | undefined>
    ) => {
      state.organizationsList = payload;
      state.isLoading = false;
    },
    organizationListFail: (state: UserState) => {
      state.isLoading = false;
    },
    addToCurrentAgreementList: (state: UserState, { payload }: PayloadAction<any>) => {
      state.currentAgreementList = [...state.currentAgreementList, { ...payload }];
    },
    removeFromCurrentAgreementList: (state: UserState, { payload }: PayloadAction<any>) => {
      state.currentAgreementList = state.currentAgreementList.filter((item: any) => item.AgreementType !== 4);
    },
    setSessionToken: (state: UserState, { payload }: PayloadAction<string | undefined>) => {
      state.sessionToken = payload;
    },
    setH: (state: UserState, { payload }: PayloadAction<string>) => {
      state.h = payload;
    },
    setCustomerNumber: (state: UserState, { payload }: PayloadAction<number | undefined>) => {
      state.customerNumber = payload;
      state.isMiniAppLogin = true;
    },
    setMiniAppUserInformation: (state: UserState, { payload }: PayloadAction<any>) => {
      state.name = payload.Name;
      state.surname = payload.Surname;
      state.email = payload.Email;
      state.birthDate = payload.BirthDate;
      state.digitalAccount = payload.DigitalAccount;
      state.currentAgreementList = payload.CurrentAgreementList;
      state.hesCode = payload.HESCode;
    },
    updateAgreementList: (state: UserState, { payload }: PayloadAction<any>) => {
      const version = payload?.find((e: any) => e.AgreementType === 7).AgreementVersion;
      state.currentAgreementList = [
        { ...state.currentAgreementList },
        { AgreementType: 7, AgreementVersion: version, Required: false, ReadOnly: false },
      ];
    },
    resetUserStore: (state: UserState) => initialState,
    setReCaptchaToken: (state: UserState, { payload }: PayloadAction<string | null>) => {
      state.reCaptchaToken = payload;
    },
  },
});

export const userStore = (state: RootState) => state.user;
export const userIsLoading = (state: RootState) => state.user.isLoading;
export const loginFormStep = (state: RootState) => state.user.loginFormStep;
export const forgotPasswordFormStep = (state: RootState) => state.user.forgotPasswordFormStep;

export const {
  onLoginRequest,
  onLoginRequestSuccess,
  onLoginRequestFail,
  onConfirmLoginSmsRequest,
  onConfirmLoginSmsRequestSuccess,
  onConfirmLoginSmsRequestFail,
  onSendTokenChangePasswordRequest,
  onSendTokenChangePasswordSuccess,
  onSendTokenChangePasswordFail,
  onConfirmSmsRequest,
  onConfirmSmsRequestSuccess,
  onConfirmSmsRequestFail,
  incrementLoginFormStep,
  decrementLoginFormStep,
  incrementForgotPasswordFormStep,
  decrementForgotPasswordFormStep,
  resetForgotPasswordFormStep,
  onConfirmSmsRequestForForgotPassword,
  onConfirmSmsRequestForForgotPasswordFail,
  insertOrUpdateHesCodeRequest,
  insertOrUpdateHesCodeRequestSuccess,
  insertOrUpdateHesCodeRequestFail,
  incrementHesCodeState,
  decrementHesCodeState,
  setUserFavoriteCard,
  setFalseUserResetPassword,
  setUserAdditionalInfo,
  onUpdateCommercialStatusRequest,
  onUpdateCommercialStatusRequestSuccess,
  onUpdateCommercialStatusRequestFail,
  onChangeCustomerInfoRequest,
  onChangeCustomerInfoRequestSuccess,
  onChangeCustomerInfoRequestFail,
  onChangePhoneValidationRequest,
  onChangePhoneValidationRequestSuccess,
  onChangePhoneValidationRequestFail,
  organizationTypeListRequest,
  organizationTypeListSuccess,
  organizationTypeListFail,
  organizationListRequest,
  organizationListSuccess,
  organizationListFail,
  resetUserStore,
  addToCurrentAgreementList,
  setH,
  removeFromCurrentAgreementList,
  setSessionToken,
  setCustomerNumber,
  setMiniAppUserInformation,
  updateAgreementList,
  setReCaptchaToken,
} = userSlice.actions;

export default userSlice.reducer;
