import { Box, Button, Link } from '@material-ui/core';
import { DialogWithActions, FormErrorWrapper, FormWrapper, FullPageWrapper, StepWrapper } from 'Components';
import { formNames, formikGlobalConfig } from 'Config';
import { useLocalization, useModal } from 'Hooks';
import { routePath } from 'Navigator/routes';
import { loginNormalize } from 'Normalize';
import { onGetAgreementRequest, storeAccount } from 'Stores/Account';
import { close651ErrorDialog, storeDialog } from 'Stores/Dialog';
import { userStore } from 'Stores/User';
import {
  decrementLoginFormStep,
  loginFormStep,
  onLoginRequest,
  resetForgotPasswordFormStep,
  setReCaptchaToken,
} from 'Stores/User/slices';
import { onCheckNumberRequest } from 'Stores/Welcome';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { agreementsTypes, generateLanguageParam } from 'Utils';
import { LoginSchema, LoginSchemaInputs, loginSchemaInitialValues } from 'Validations';
import { push } from 'connected-react-router';
import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import { FunctionComponent, memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StaticContext, useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import './Login.scss';
import { LoginPasswordStep } from './LoginPasswordStep';
import { LoginPhoneStep } from './LoginPhoneStep';

type LoginParams = { countryCode?: string; phoneNumber?: string };
type LoginProps = RouteComponentProps<{}, StaticContext, LoginParams>;

const Login: FunctionComponent<LoginProps> = memo(({ location: { state } }) => {
  const {
    web_link_btn_forgot_password,
    web_btn_next,
    web_btn_sign_in,
    web_title_5_incorrect_password,
    web_desc_5_incorrect_password,
    web_btn_change_password_5_incorrect_password,
    web_btn_vazgec,
  } = useLocalization();

  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef<any>();

  const step = useSelector(loginFormStep);
  const phoneNumber = get(state, 'phoneNumber');
  const countryCode = get(state, 'countryCode');
  const isRecaptchaSkip = ['istanbulkart-web-test.web.app', 'istanbulkart-tmob-test.web.app', 'localhost'].includes(
    window?.location?.hostname
  );

  const { is651ErrorDialogOpen, isOtpOpen } = useSelector(storeDialog);
  const { reCaptchaToken } = useSelector(userStore);
  const { isVisible, show, hide } = useModal();
  const { web_login_clarification_text } = useLocalization();
  const accountStore = useSelector(storeAccount);
  const { agreementContent } = accountStore;

  function onChange(value: string | null) {
    dispatch(setReCaptchaToken(value));
  }

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname !== '/login') {
        dispatch(setReCaptchaToken(null));
      }
    });
  }, []);
  useEffect(() => {
    if (!phoneNumber) {
      dispatch(decrementLoginFormStep());
    }
    dispatch(resetForgotPasswordFormStep());
  }, []);

  const handleAgreementSelect = (AgreementType: number) => {
    dispatch(onGetAgreementRequest({ AgreementType, Language: generateLanguageParam(), isSignupProcess: true }));
  };

  const handleSubmit = (values: LoginSchemaInputs) => {
    if (step === 0) {
      dispatch(
        onCheckNumberRequest({
          CountryCode: values.countryCode,
          Cellphone: values.phone,
        })
      );
    }
    if (step === 1) {
      dispatch(
        onLoginRequest({
          onLoginData: loginNormalize(values.password, phoneNumber, countryCode),
          formName: formNames.login,
        })
      );
      formRef.current.setFieldValue('password', '');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', enterHandler);
    return () => {
      document.removeEventListener('keydown', enterHandler);
    };
  }, [step, isOtpOpen, reCaptchaToken]);

  const enterHandler = useCallback(
    (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        setTimeout(() => {
          if (step === 0) {
            dispatch(
              onCheckNumberRequest({
                CountryCode: formRef?.current?.values?.countryCode,
                Cellphone: formRef?.current?.values?.phone,
              })
            );
          }

          if (step === 1 && !isOtpOpen && (reCaptchaToken !== null || isRecaptchaSkip)) {
            dispatch(
              onLoginRequest({
                onLoginData: loginNormalize(formRef?.current?.values?.password, phoneNumber, countryCode),
                formName: formNames.login,
              })
            );
            formRef.current.setFieldValue('password', '');
          }
        }, 1);
      }
    },
    [step, isOtpOpen, reCaptchaToken]
  );

  const renderStep = (formikProps: FormikProps<LoginSchemaInputs>) => {
    switch (step) {
      case 0:
        return <LoginPhoneStep formikProps={formikProps} />;
      case 1:
        return <LoginPasswordStep formikProps={formikProps} />;
      default:
        break;
    }
  };

  return (
    <div id="login">
      <FullPageWrapper>
        <>
          <StepWrapper>
            <FormErrorWrapper formName={formNames.login} step={step} resetFormOnRouteChange>
              <Formik
                initialValues={loginSchemaInitialValues}
                validationSchema={LoginSchema[step]}
                onSubmit={handleSubmit}
                innerRef={formRef}
                {...formikGlobalConfig}
              >
                {(formikProps) => (
                  <FormWrapper onSubmit={formikProps.handleSubmit}>
                    {renderStep(formikProps)}
                    <Button
                      disabled={!reCaptchaToken && !isRecaptchaSkip}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      type="submit"
                      itemID="loginPhoneStepBtnTest"
                    >
                       {step === 0 ? web_btn_next : web_btn_sign_in}
                    </Button>
                    {step === 1 && (
                      <Box textAlign="center" marginTop={gutters.big} fontWeight={600}>
                        <Link
                          itemID="loginForgotPasswordBtnTest"
                          onClick={() => {
                            dispatch(
                              push(routePath.forgotPassword, {
                                countryCode,
                                phoneNumber,
                              })
                            );
                          }}
                          color="secondary"
                          underline="none"
                        >
                          {web_link_btn_forgot_password}
                        </Link>
                      </Box>
                    )}
                  </FormWrapper>
                )}
              </Formik>
            </FormErrorWrapper>
          </StepWrapper>
          <Box
            onClick={() => {
              handleAgreementSelect(agreementsTypes.AYDINLATMA_METNI);
              show();
            }}
            className={`clarification-text ${step == 0 ? 'step1' : ''}`}
            fontWeight="bold"
            color={colors.primary}
            fontSize={fontSizes.regular}
            itemID="openPhotoRuleBtnTest"
          >
            {web_login_clarification_text}
          </Box>
        </>
      </FullPageWrapper>
      {/** 5 Times wrong password error modal*/}
      <DialogWithActions
        title={web_title_5_incorrect_password}
        text={web_desc_5_incorrect_password}
        primaryButton={{
          label: web_btn_change_password_5_incorrect_password,
          handleClick: () => {
            dispatch(close651ErrorDialog());
            history.push(routePath.forgotPassword, {
              countryCode,
              phoneNumber,
            });
          },
        }}
        secondaryButton={{
          label: web_btn_vazgec,
          handleClick: () => {
            dispatch(close651ErrorDialog());
          },
        }}
        hide={() => dispatch(close651ErrorDialog())}
        open={is651ErrorDialogOpen}
      />
      <DialogWithActions
        title={web_login_clarification_text}
        text={agreementContent?.AgreementContent}
        open={isVisible}
        hide={hide}
      />
    </div>
  );
});

export default Login;
