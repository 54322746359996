import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useCardType, useCreditCardType, useLocalization } from 'Hooks';
import { Box, Typography, Grid, Button, FormHelperText } from '@material-ui/core';
import { fontSizes, gutters } from 'Theme/Variables';
import { cardBalanceNormalize, getEnvironment, MasterPassRefType, MasterPassTokenEnum } from 'Utils';
import { FormErrorWrapper } from 'Components';
import { ChooseCard } from './ChooseCard';
import { ChooseCreditCard } from './ChooseCreditCard';
import { useDispatch, useSelector } from 'react-redux';
import { getCommissionByBinNumberNormalize, masterPassPaymentNormalize, VsPolBelbimNormalize } from 'Normalize';
import { UserState, userStore } from 'Stores/User';
import { CardTransactionsState, setActiveSelectedCard, storeCardTransactions } from 'Stores/CardTransactions';
import { cardItemParams } from 'Views/UserCards/AllCards/CardItem/constant';
import {
  close3DSecureModal,
  creditCardTransactionsStore,
  onGetCommissionByBinNumberRequest,
  onGetMasterpassTokenRequest,
  setActiveSelectedCreditCard,
  setLoadAmount,
  vsPosBelbimPaymentRequest,
} from 'Stores/CreditCardTransactions';
import classNames from 'classnames';
import { formNames } from 'Config';
import { storeWelcome, WelcomeState } from 'Stores/Welcome';
import { Modal3DSecure } from 'Components';
import { refTrnType } from '../constant';

import './LoadBalance.scss';
import { useMemo } from 'react';

type LoadBalanceProps = {};

const LoadBalance: FC<LoadBalanceProps> = () => {
  const {
    web_title_amount,
    web_desc_total_amount,
    web_desc_trasaction_fee,
    msg_web_upload_tl_add_debit_card_success,
    msg_web_upload_tl_add_istanbulkart_success,
    msg_web_upload_tl_add_debit_card_no_check,
    web_btn_go_to_pay,
    web_10tl,
    web_20tl,
    web_30tl,
    web_40tl,
    web_50tl,
    web_100tl,
    web_150tl,
    web_200tl,
    web_comission_zero,
    msg_web_zorunlu_alan,
    card_colour_digital_card_activation_control,
  } = useLocalization();

  const dispatch = useDispatch();

  const userData: UserState = useSelector(userStore);
  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const welcomeData: WelcomeState = useSelector(storeWelcome);

  const {
    commissionAmount,
    creditCardList,
    activeSelectedCreditCard,
    userTempCreditCard,
    loading,
    loadAmount,
    is3DSecureModalOpen,
    vsPosBelbim3DsecureUrl,
    activePayment,
    isUserAddedCreditCardWithMasterpass,
  } = useSelector(creditCardTransactionsStore);

  const { Cellphone } = welcomeData;
  const customerNumber = userData?.customerNumber;
  const sessionToken = userData?.sessionToken;

  const userCards: cardItemParams[] = cardData?.cards;
  const userPrimaryCard = userCards?.filter((item) => item.IsPrimary === true) ?? [];
  const { activeSelectedCard, isUserAddingNewCardInTransactions, tempCardInfo }: any = cardData;
  const { cardColor, cardPath } = useCardType(userPrimaryCard ? userPrimaryCard[0]?.ProductCode : '');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { creditCardBrand } = useCreditCardType(userTempCreditCard);

  useEffect(() => {
    // If user comes this screen from transactions show primary card
    if (userPrimaryCard && userPrimaryCard?.length > 0 && !activeSelectedCard) {
      dispatch(
        setActiveSelectedCard({
          ...userPrimaryCard[0],
          cardColorClass: cardColor || '',
          cardPath: cardPath || '',
        })
      );
    }
    // If user have masterpass cards show first added card
    if (creditCardList && creditCardList?.length > 0 && !activeSelectedCreditCard && !userTempCreditCard) {
      dispatch(setActiveSelectedCreditCard(creditCardList[creditCardList?.length - 1]));
    }
  }, [creditCardList]);

  const isDijitalCard = card_colour_digital_card_activation_control?.includes(activeSelectedCard?.ProductCode);

  const handleCommission = (item: any) => {
    dispatch(setLoadAmount(Number(item.key.replace(' TL', ''))));
    dispatch(
      onGetCommissionByBinNumberRequest(
        getCommissionByBinNumberNormalize(
          Number(item.key.replace(' TL', '')),
          activeSelectedCreditCard?.Value1?.slice(0, 6),
          activeSelectedCard?.CardBalance,
          customerNumber,
          sessionToken
        )
      )
    );
  };

  const handleSubmitPayment = () => {
    if (!activeSelectedCard || !activeSelectedCreditCard || !loadAmount) {
      setErrorMessage(true);
      return;
    }
    if (!isUserAddedCreditCardWithMasterpass && userTempCreditCard) {
      dispatch(
        onGetMasterpassTokenRequest({
          activeSelectedCreditCard: activeSelectedCreditCard,
          SessionToken: sessionToken,
          CustomerNumber: customerNumber,
          TokenType: MasterPassTokenEnum.REGISTER_PURCHASE,
          RefTrnType: isDijitalCard ? MasterPassRefType.DIGITAL_CARD_BALANCE : MasterPassRefType.CARD_BALANCE,
          Amount: loadAmount,
          CommissionAmount: commissionAmount,
          DeviceType: '10',
          MifareId: activeSelectedCard?.MifareId,
          PanMasked: activeSelectedCreditCard?.Value1,
          MacroMerchantId: getEnvironment() ? '05878207920408103103242' : '06580207920724020852449',
          PmtAccountId: isDijitalCard ? userData?.digitalAccount?.AccountId : 0,
          request: {
            data: masterPassPaymentNormalize(loadAmount + (commissionAmount || 0), activeSelectedCreditCard?.Name),
          },
        })
      );
    } else {
      dispatch(
        onGetMasterpassTokenRequest({
          SessionToken: sessionToken,
          CustomerNumber: customerNumber,
          TokenType: MasterPassTokenEnum.REGISTER_PURCHASE,
          RefTrnType: isDijitalCard ? MasterPassRefType.DIGITAL_CARD_BALANCE : MasterPassRefType.CARD_BALANCE,
          Amount: loadAmount,
          CommissionAmount: commissionAmount,
          DeviceType: '10',
          MifareId: activeSelectedCard?.MifareId,
          PanMasked: activeSelectedCreditCard?.Value1,
          MacroMerchantId: getEnvironment() ? '05878207920408103103242' : '06580207920724020852449',
          PmtAccountId: isDijitalCard ? userData?.digitalAccount?.AccountId : 0,
          request: {
            data: masterPassPaymentNormalize(loadAmount + (commissionAmount || 0), activeSelectedCreditCard?.Name),
          },
        })
      );
    }
  };

  const prices = [
    {
      amount: 10,
      key: web_10tl,
    },
    {
      amount: 20,
      key: web_20tl,
    },
    {
      amount: 30,
      key: web_30tl,
    },
    {
      amount: 40,
      key: web_40tl,
    },
    {
      amount: 50,
      key: web_50tl,
    },
    {
      amount: 100,
      key: web_100tl,
    },
    {
      amount: 150,
      key: web_150tl,
    },
    {
      amount: 200,
      key: web_200tl,
    },
  ];

  const priceFontNormalize = useCallback((price: string) => {
    const splittedPrice = price?.split(' ');
    return (
      <Fragment>
        <span>{splittedPrice[0]}</span> <span style={{ fontSize: '10px' }}>{splittedPrice[1]}</span>
      </Fragment>
    );
  }, []);

  return (
    <FormErrorWrapper formName={formNames.addBalanceToCard} step={loadAmount} resetFormOnRouteChange>
      <div id="loadBalance">
        <Box>
          <ChooseCard selectedCard={activeSelectedCard} />
          {isUserAddingNewCardInTransactions && tempCardInfo && (
            <Typography variant="body1" className="loadBalance__tempCard">
              {msg_web_upload_tl_add_istanbulkart_success}
            </Typography>
          )}
          {errorMessage && !activeSelectedCard && <FormHelperText error>{msg_web_zorunlu_alan}</FormHelperText>}
        </Box>
        <Box>
          <ChooseCreditCard creditCardList={creditCardList} selectedCreditCard={activeSelectedCreditCard} />
          {userTempCreditCard && (
            <Typography variant="body1" className="loadBalance__tempCreditCard">
              {isUserAddedCreditCardWithMasterpass
                ? msg_web_upload_tl_add_debit_card_success
                : msg_web_upload_tl_add_debit_card_no_check}
            </Typography>
          )}
          {errorMessage && !activeSelectedCreditCard && <FormHelperText error>{msg_web_zorunlu_alan}</FormHelperText>}
        </Box>

        <Typography variant="h6">
          <Box fontWeight="bold" marginBottom={fontSizes.regular} marginTop={fontSizes.big}>
            {web_title_amount}
          </Box>
        </Typography>
        <Box>
          <Grid container className="loadBalance__balanceItemContainer">
            {prices.map((item) => (
              <Grid
                item
                xs={4}
                sm={3}
                key={item?.amount}
                className="loadBalance__balanceItemWrapper"
                onClick={() => {
                  handleCommission(item);
                }}
              >
                <Box
                  className={classNames({
                    loadBalance__balanceItem: true,
                    loadBalance__balanceItemActive: Number(item.key.replace(' TL', '')) === loadAmount,
                  })}
                  itemID="loadAmountBtnTest"
                >
                  {priceFontNormalize(item.key)}
                </Box>
              </Grid>
            ))}
          </Grid>
          {errorMessage && !loadAmount && <FormHelperText error>{msg_web_zorunlu_alan}</FormHelperText>}
          <Box className="loadBalance__comisson">
            {commissionAmount === 0 && !loading && (
              <Typography variant="body1" align="center">
                <Box fontSize={fontSizes.small}>{web_comission_zero}</Box>
              </Typography>
            )}
            {commissionAmount !== undefined && commissionAmount !== 0 && !loading && (
              <Typography variant="body1" align="center">
                <Box fontSize={fontSizes.small}>
                  {commissionAmount} {web_desc_trasaction_fee}
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="body1" className="loadBalance__totalBalance">
            {web_desc_total_amount}
          </Typography>
          <Typography variant="h3" align="center">
            <Box fontWeight="bold" marginBottom={gutters.big} fontSize={fontSizes.big} className="loadBalance__payment">
              {!loading && <Box>{cardBalanceNormalize(loadAmount + (commissionAmount || 0))} TL</Box>}
            </Box>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            type="submit"
            onClick={handleSubmitPayment}
            itemID="uploadTLBtnTest"
          >
            {web_btn_go_to_pay}
          </Button>
        </Box>
        {/* RERENDERI  VE GEREKSIZ SORGUYU ENGELLENMEK İÇİN  */}
        {is3DSecureModalOpen && (
          <Modal3DSecure
            open={is3DSecureModalOpen}
            link={vsPosBelbim3DsecureUrl}
            paymentNo={activePayment}
            close={() => dispatch(close3DSecureModal())}
          />
        )}
      </div>
    </FormErrorWrapper>
  );
};

export default LoadBalance;
