import { memo, useCallback, useEffect, useRef } from 'react';
import { FormWrapper, InputPhone } from 'Components';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { colors } from 'Theme/Variables';
import { WelcomeSchema, welcomeSchemaInitialValues, WelcomeSchemaInputs } from 'Validations/WelcomeSchema';
import { Formik } from 'formik';
import { useErrorMessage, useLocalization } from 'Hooks';
import { WelcomeGetApp } from './WelcomeGetApp';
import { useDispatch } from 'react-redux';
import { onCheckNumberRequest } from 'Stores/Welcome';
import { formikGlobalConfig } from 'Config';

import './WelcomeSignIn.scss';

const WelcomeSignIn = memo(() => {
  const dispatch = useDispatch();

  const { getError, getErrorMessage } = useErrorMessage();
  const { web_title_welcome, web_desc_welcome, web_btn_next } = useLocalization();

  const handleSubmit = (values: WelcomeSchemaInputs) => {
    dispatch(
      onCheckNumberRequest({
        CountryCode: values.countryCode,
        Cellphone: values.phone,
      })
    );
  };

  const inputRef = useRef<any>();

  useEffect(() => {
    document.addEventListener('keydown', enterHandler);
    return () => {
      document.removeEventListener('keydown', enterHandler);
    };
  }, []);

  const enterHandler = useCallback((event: any) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (inputRef.current.initialValues.phone.length === 5) {
        inputRef.current.handleSubmit();
      }
    }
  }, []);

  return (
    <Grid item lg={5} id="welcomeSignIn">
      <Box>
        <Typography variant="h4">
          <Box className="welcomeSignIn-title" fontWeight="Bold">
            {web_title_welcome}
          </Box>
        </Typography>
        <Typography variant="h5">
          <Box className="welcomeSignIn-desc" color={colors.alternative}>
            {web_desc_welcome}
          </Box>
        </Typography>
        <Formik
          initialValues={welcomeSchemaInitialValues}
          validationSchema={WelcomeSchema}
          onSubmit={handleSubmit}
          innerRef={inputRef}
          {...formikGlobalConfig}
        >
          {({
            values: { phone, countryCode },
            handleChange,
            setFieldValue,
            touched: { phone: touchedPhone },
            errors: { phone: errorPhone },
            handleSubmit,
          }) => (
            <FormWrapper onSubmit={handleSubmit}>
              <Box display="flex" flexWrap="wrap">
                <Box className="welcomeSignIn-inputContainer">
                  <InputPhone
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    setCountryCode={setFieldValue}
                    countryCode={countryCode}
                    countryCodeField={'countryCode'}
                    resetPhone={() => setFieldValue('phone', '')}
                    error={getError(errorPhone, touchedPhone)}
                    helperText={getErrorMessage(errorPhone, touchedPhone)}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  className="welcomeSignIn-button"
                  itemID="welcomeSignInButtonTest"
                >
                  {web_btn_next}
                </Button>
              </Box>
            </FormWrapper>
          )}
        </Formik>
      </Box>
      <WelcomeGetApp showOnlyDesktop />
    </Grid>
  );
});

export default WelcomeSignIn;
