import { Box, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useErrorMessage, useLocalization } from 'Hooks';
import { FC, memo } from 'react';
import { gutters, fontSizes } from 'Theme/Variables';
import { LoginSchemaInputs } from 'Validations';
import { InputPhone } from 'Components';
import { HandIcon } from 'Components/Svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { setReCaptchaToken } from 'Stores/User';
import { useDispatch } from 'react-redux';

type LoginPasswordStepProps = {
  formikProps: FormikProps<LoginSchemaInputs>;
};

const CAPTCHA_KEYS = [
  {
    // Prod
    domain: 'bireysel.istanbulkart.istanbul',
    key: '6LeoTRclAAAAABva4CEBXtLMYltrxcTRDS1TgC5j',
  },
  {
    // PreProd
    domain: 'istanbulkart-web-preprod.web.app',
    key: '6LdAtrMeAAAAAP8eM1XVMjs1DKu--eTddUEzl36L',
  },
  {
    // Staging
    skip: true,
    domain: 'istanbulkart-tmob-test.web.app',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
  {
    // Staging
    skip: true,
    domain: 'istanbulkart-web-test.web.app',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
  {
    // Local
    skip: true,
    domain: 'localhost',
    key: '6LfJpKUeAAAAADkqdusUJP9-dPurtarbMYgxrkwb',
  },
];
const LoginPhoneStep: FC<LoginPasswordStepProps> = memo(
  ({
    formikProps: {
      setFieldValue,
      handleChange,
      handleBlur,
      values: { phone, countryCode },
      errors: { phone: errorPhone },
      touched: { phone: touchedPhone },
    },
  }) => {
    const dispatch = useDispatch();
    const { web_title_firstscreen, web_desc_firstscreen } = useLocalization();
    const { getError, getErrorMessage } = useErrorMessage();
    const hostName = window.location.hostname;
    const captchaConfig = CAPTCHA_KEYS.find((item) => item.domain === hostName);

    function onChange(value: string | null) {
      dispatch(setReCaptchaToken(value));
    }

    return (
      <>
        <Typography variant="h5" align="center">
          <Box
            fontWeight={600}
            marginBottom={gutters.regular}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {web_title_firstscreen}
            <Box marginLeft={1}>
              <HandIcon />
            </Box>
          </Box>
        </Typography>
        <Typography variant="body1" align="center" component="span">
          <Box marginBottom={gutters.big} fontSize={fontSizes.regular}>
            {web_desc_firstscreen}
          </Box>
        </Typography>
        <Box marginBottom={gutters.big}>
          <InputPhone
            name="phone"
            value={phone}
            onChange={handleChange}
            setCountryCode={setFieldValue}
            countryCode={countryCode}
            countryCodeField={'countryCode'}
            type="tel"
            resetPhone={() => setFieldValue('phone', '')}
            onBlur={handleBlur}
            error={getError(errorPhone, touchedPhone)}
            helperText={getErrorMessage(errorPhone, touchedPhone)}
          />
        </Box>
        {!captchaConfig?.skip && (
          <div id="reCAPTCHA">
            <ReCAPTCHA sitekey={captchaConfig?.key || ''} onChange={onChange} />
          </div>
        )}
      </>
    );
  }
);

export default LoginPhoneStep;
