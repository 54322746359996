import './WelcomeGetApp.scss';
import { Box, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import GetAppStorePath from 'Assets/Icons/get-appstore.svg';
import GetGoogleStorePath from 'Assets/Icons/get-google.svg';
import GetHuaweiPath from 'Assets/Icons/get-huawei.svg';
import { FunctionComponent, memo } from 'react';
import classNames from 'classnames';
import { useLocalization } from 'Hooks';
import { isAndroid, isIOS } from 'react-device-detect';

type WelcomeGetAppProps = {
  showOnlyMobile?: boolean;
  showOnlyDesktop?: boolean;
};

const WelcomeGetApp: FunctionComponent<WelcomeGetAppProps> = memo(({ showOnlyMobile, showOnlyDesktop }) => {
  const { web_title_download_app, web_appgallery_link, web_appstore_link, web_playstore_link } = useLocalization();

  const openLinks = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box
      marginBottom={gutters.large}
      id="storeLinks"
      className={classNames({
        'storeLinks--onlyDesktop': showOnlyDesktop,
        'storeLinks--onlyMobile': showOnlyMobile,
      })}
    >
      <Typography variant="body1" component="div">
        <Box marginBottom={gutters.regular} fontSize={fontSizes.regular} color={colors.secondary} fontWeight="bold">
          {web_title_download_app}
        </Box>
      </Typography>
      <Box className="storeLinks-imageContainer">
        {!isIOS && (
          <img
            src={GetGoogleStorePath}
            alt="İstanbul Kart Android"
            onClick={() =>
              openLinks('https://play.google.com/store/apps/details?id=com.belbim.istanbulkart&hl=tr&gl=US')
            }
            itemID="welcomePlayStoreTest"
          />
        )}

        {!isAndroid && (
          <img
            src={GetAppStorePath}
            alt="İstanbul Kart iOS"
            onClick={() => openLinks('https://apps.apple.com/tr/app/istanbulkart/id1352307391?l=tr')}
            itemID="welcomeAppStoreTest"
          />
        )}
        {!isIOS && (
          <img
            src={GetHuaweiPath}
            alt="İstanbul Kart App Gallery"
            onClick={() =>
              openLinks(
                'https://appgallery.cloud.huawei.com/ag/n/app/C102347171?locale=tr_TR&source=appshare&subsource=C102347171'
              )
            }
            itemID="welcomeAppGalleryTest"
          />
        )}
      </Box>
    </Box>
  );
});

export default WelcomeGetApp;
